<template>
  <app-button class="hover:tw-underline tw-p-0 tw-m-0" state="text" :disabled="disabled || loading" :loading="loading" @click="downloadReport">
    <div class="tw-flex tw-items-center tw-gap-2">
      <download-icon class="tw-w-space-4 tw-h-space-4 tw-text-jb-grey-700" />
      <app-text weight="medium" class=" tw-text-sm">
        Export
      </app-text>
    </div>
  </app-button>
</template>

<script>
import AppButton from '@/components/AppButton';
import AppText from '@/components/AppText';
import DownloadIcon from '@/assets/svg/download-icon.svg';

export default {
  name: 'AffiliateReportingExportButton',
  components: {
    AppButton,
    DownloadIcon,
    AppText
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    downloadReport() {
      this.$emit('download-csv');
    }
  }
};
</script>
<style lang="scss">
.affiliate-reporting-export-button {
  height: 50px;
  font-size: $--clb-font-size-sm;

  span {
    line-height: 15px;
  }
}
</style>
