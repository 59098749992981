<template>
  <div v-scrollable:xy class="affiliate-reporting-table">
    <el-table
      ref="table"
      :data="dynamicTableData"
      :cell-style="setCellStyle"
      :show-summary="showSummary"
      :summary-method="getSummary"
      :default-sort="defaultSort"
      stripe
      @cell-click="handleCellClick"
      @sort-change="handleSortChange">
      <slot />
    </el-table>
    <portal v-if="showStickyHeader" :to="portalTo">
      <div
        :id="stickySinkId"
        ref="sink"
        class="el-table affiliate-reporting-table"
        @click="clickDelegation" />
    </portal>
    <el-pagination
      v-if="!loading && hasPagination"
      :total="pagination.total"
      :page-size="pagination.perPage"
      :current-page="parseInt(pagination.page)"
      :pager-count="5"
      class="affiliate-reporting-table__pagination"
      layout="prev, pager, next"
      @current-change="$emit('page-change', $event)" />
  </div>
</template>

<script>
import { Table, TableColumn } from 'element-ui';
import { GradientHelper } from '@/helpers/GradientHelper';
import StickyHeaderMixin from '@/mixins/StickyHeaderMixin';

export default {
  name: 'AffiliateReportingTable',
  components: {
    'el-table': Table,
    'el-table-column': TableColumn
  },
  mixins: [StickyHeaderMixin],
  props: {
    tableData: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    skeletonRows: {
      type: Number,
      default: 5
    },
    columns: {
      type: Array,
      required: true
    },
    tableSummary: {
      type: Array,
      default() {
        return [];
      }
    },
    pagination: {
      type: Object,
      default() {
        return {};
      }
    },
    defaultSort: {
      type: Object,
      default() {
        return {};
      }
    },
    stickySinkId: {
      type: String,
      default: 'affiliate-reporting-table-sink'
    },
    portalTo: {
      type: String,
      default() {
        return 'app-header-subcontent';
      }
    }
  },
  computed: {
    showSummary() {
      return this.tableSummary.length > 0;
    },
    dynamicTableData() {
      return !this.loading ? this.tableData : new Array(this.skeletonRows).fill(this.loaderRow);
    },
    loaderRow() {
      return [...this.columns].reduce((rows, key) => {
        rows[key] = null;
        return rows;
      }, {});
    },
    hasPagination() {
      return this.pagination.total > 0;
    },
    gradientHelper() {
      // Todo: use a computed property to get actual min and max values
      return new GradientHelper({
        min: 0,
        max: 10000
      });
    }
  },
  methods: {
    getSummary() {
      return this.tableSummary;
    },
    getColorCode(value) {
      return this.gradientHelper.getColorCode(value);
    },
    setCellStyle(cell) {
      const base = { padding: '0', height: '50px' };
      const background = cell.column.property === 'money' ? { background: this.getColorCode(cell.row.money) } : {};

      return Object.assign(base, background);
    },
    handleCellClick(row, column, cell, event) {
      if (!this.loading) {
        this.$emit('cell-click', row, column, cell, event);
      }
    },
    handleSortChange(data) {
      if (this.hasPagination) {
        this.$emit('on-sort', data);
      }
    }
  }
};
</script>
<style lang="scss">
.affiliate-reporting-table {
  position: relative;
  width: 100%;

  &__column--disabled {
    pointer-events: none;
  }

  &__item--action {
    color: $--clb-color-primary;
    cursor: pointer;
    text-decoration: underline;
  }

  &.el-table,
  .el-table,
  .el-table__fixed,
  &.el-table__fixed {
    &::before {
      display: none;
    }

    &__fixed {
      box-shadow: none;

      &-header-wrapper,
      &-body-wrapper {
        z-index: auto;
      }
    }

    &__empty-block {
      width: auto !important;
    }

    .el-table__body-wrapper {
      overscroll-behavior-x: none;
    }

    .cell {
      font-size: $--clb-font-size-xs;
      line-height: $--clb-font-size-sm;
      overflow: hidden;
      text-overflow: ellipsis;

      .skeleton-box {
        display: block;
      }
    }

    td,
    th {
      color: $--clb-body-font;

      &:first-child {
        text-align: left;
      }
    }

    thead {
      padding: $--clb-space-4;

      th {
        padding: 6px 0;

        .cell {
          font-weight: 700;
          white-space: nowrap;
          padding: 0 18px 0 0;
          position: relative;
          overflow: visible;
          overflow-x: hidden;
          line-height: 34px;

          &:first-child {
            padding-left: $--clb-space-2;

            .caret-wrapper {
              position: absolute;
              right: 0;
            }
          }
        }
      }

      th:not(:first-child) {
        .cell {
          padding-right: 18px;
        }

        .caret-wrapper {
          width: 18px;
          margin-left: 4px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }

    .el-table__body {
      tbody {
        tr {
          transition: 0.3s ease;
          cursor: pointer;

          td:first-child .cell {
            color: #262626;
            font-weight: 700;
          }

          .cell {
            padding: 0 $--clb-space-2;
          }
        }
      }
    }

    .el-table__footer-wrapper tbody td {
      background: $--clb-color-accent__dark;
      color: $--border-color-base;
      font-weight: 700;

      .cell {
        padding: 0 $--clb-space-2;
      }
    }
  }

  &__pagination {
    margin-top: $--clb-space-1;
    padding: 0;

    .el-pager {
      li.active {
        color: $--clb-color-accent__dark;
      }
    }
  }
}

.app-header__portal,
.app-right-sidebar__header--portal {
  .affiliate-reporting-table {
    position: relative;
    width: 100%;
    padding: 0 $--clb-mobile-padding;

    th.is-leaf,
    td {
      border-bottom: none;
      background: $--clb-color-primary__white;
    }
  }
}

@media (min-width: $--sm) {
  .app-header__portal {
    .affiliate-reporting-table {
      padding: 0 $--clb-layout-4 0 $--clb-layout-4;
      margin: 0 auto;

      .el-table__fixed {
        left: $--clb-layout-4;
      }
    }
  }

  .app-right-sidebar__header--portal {
    .affiliate-reporting-table {
      padding: 0 $--clb-layout-2;
    }
  }
}

@media (min-width: $--md) {
  .app-header__portal {
    .affiliate-reporting-table {
      padding: 0 $--clb-layout-2 + $--clb-mobile-padding;
    }
  }
}

@media (min-width: $--lg) {
  .app-header__portal {
    .affiliate-reporting-table {
      padding: 0 $--clb-layout-4 0 $--clb-layout-4;
    }
  }
}
</style>
