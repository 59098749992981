import queryStringOptions from '@/services/query-string-options';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import _difference from 'lodash/difference';
import _pick from 'lodash/pick';
import _intersection from 'lodash/intersection';
import { INVERTED_ORDER_MAP, ORDER_MAP } from '@/constants/order';
import { COLUMN_MAP, COLUMN_DEFAULTS } from '@/constants/columns';
import { GROUP_OPTIONS } from '@/constants/group';
import { PERIOD_TYPES_OPTIONS } from '@/constants/periodTypes';
import { MAX_DOWNLOAD_SIZE } from '@/constants/downloadSize';

export default {
  data() {
    return {
      columnWhiteListMap: Object.freeze(COLUMN_MAP),
      subids: Object.freeze(GROUP_OPTIONS),
      periodTypes: Object.freeze(PERIOD_TYPES_OPTIONS),
      pagination: {
        total: 1,
        page: 1,
        perPage: 25
      },
      dateOptions: this.$store.getters.getMetricDateOptions,
      reportOptions: this.getDefaultReportOptions(),
      sort: {},
      loadingCsvFile: false,
      totals: []
    };
  },
  computed: {
    orgId() {
      return this.$store.getters.organizationId;
    },
    reportType() {
      return this.$route.params.reportType;
    },
    fetchingReport() {
      return this.$store.getters.fetchingReport(this.reportType, this.reportSubType);
    },
    storeOptions() {
      const { report, subReport } = this.$store.getters.affiliateReportOptions(this.reportType, this.reportSubType);
      return subReport || report;
    },
    reportData() {
      return this.$store.getters.reportData(this.reportType, this.reportSubType);
    },
    sortState() {
      return { prop: _get(this.sort, 'sortBy'), order: INVERTED_ORDER_MAP[_get(this.sort, 'order')] };
    },
    reportSummary() {
      if (this.fetchingReport) {
        return ['Total'];
      }

      const summary = this.reportColumns.map((column) => this.getFormattedColumn(this.totals, column, ''));

      summary[0] = 'Total';
      return summary;
    },
    // eslint-disable-next-line complexity,sonarjs/cognitive-complexity
    reportColumns() {
      if (!this.reportData || !this.reportData.length) {
        return _intersection(Object.keys(COLUMN_MAP), COLUMN_DEFAULTS);
      }

      return Object.keys(COLUMN_MAP).filter(key => {
        // Excludes money column based on permission
        const hasMoneyPermissions = this.$can(this.$permissions.SUB_ID_VIEW_OWN) || this.$can(this.$permissions.SUB_ID_VIEW_COMPANY);
        if (key === 'money' && !hasMoneyPermissions) {
          return false;
        }

        if (!this.reportSubType && key === 'date') {
          return false;
        }

        if (this.excludedColumns.includes(key)) {
          return false;
        }

        if (this.reportData[0].hasOwnProperty(key)) {
          return key;
        }
      });
    },
    columnClassName() {
      return this.fetchingReport ? 'affiliate-reporting-table__column--disabled' : '';
    },
    filterCampaignIds: {
      get() {
        return _get(this.reportOptions, 'filter.campaign_id', []);
      },
      set(value) {
        if (!value.length) {
          this.reportOptions.filter = _omit(this.reportOptions.filter, 'campaign_id');
        } else {
          this.reportOptions.filter = {
            ...this.reportOptions.filter || {},
            campaign_id: value
          };
        }
      }
    },
    periodType: {
      get() {
        return _intersection(this.reportOptions.groupBy, Object.keys(PERIOD_TYPES_OPTIONS))[0];
      },
      set(value) {
        this.reportOptions.groupBy = [
          ..._difference(this.reportOptions.groupBy, Object.keys(PERIOD_TYPES_OPTIONS))
        ].concat(value ? [value] : []);

        if (this.reportSubType) {
          this.handleApplyReportOptions();
        }
      }
    },
    filterGroups: {
      get() {
        return _intersection(this.reportOptions.groupBy, Object.keys(GROUP_OPTIONS));
      },
      set(value) {
        this.reportOptions.groupBy = [
          ..._difference(this.reportOptions.groupBy, Object.keys(GROUP_OPTIONS)),
          ...value
        ];

        if (this.reportSubType) {
          this.handleApplyReportOptions();
        }
      }
    },
    hasFilters() {
      return JSON.stringify(_pick(this.reportOptions, ['filter', 'groupBy'])) !==
        JSON.stringify(_pick(this.getDefaultReportOptions(this.reportType, this.reportSubType), ['filter', 'groupBy']));
    },
    showBackBtn() {
      return !!this.$store.getters.reportNavigationStack.length;
    },
    hasGroupBy() {
      return !!this.getActiveRouteOptions().groupBy;
    }
  },
  watch: {
    storeOptions: {
      handler(newVal, oldVal) {
        if (!newVal || JSON.stringify(newVal) !== JSON.stringify(oldVal) || (this.reportSubType && this.reportSubType === this.getReportSubtype(this.$route))) {
          this.initializeRouteOptions();
        }
      },
      immediate: true
    }
  },
  methods: {
    getReportSubtype(route) {
      return _get(route, 'params.reportSubType', _get(route, 'meta.reportSubType', undefined));
    },
    getDefaultReportOptions(reportType, reportSubType) {
      let groupBy, filter;
      if (!reportSubType) {
        groupBy = reportType === 'subid' ? ['campaign_id', 'c1'] : ['campaign_id'];
      }

      return {
        ...this.dateOptions,
        ..._pick(this.reportOptions, ['dateRange', 'startDate', 'endDate']),
        groupBy,
        filter,
        page: 1,
        perPage: 25
      };
    },
    initializeRouteOptions() {
      this.pagination = { ...this.pagination, ..._pick(this.storeOptions, ['page']) };
      this.sort = _pick(this.storeOptions, ['sortBy', 'order']);
      this.reportOptions = { ...this.getDefaultReportOptions(this.reportType, this.reportSubType), ...this.storeOptions };
      this.dateOptions = _pick(this.reportOptions, ['dateRange', 'startDate', 'endDate']);

      if (!this.storeOptions || !Object.keys(this.storeOptions).length) {
        return this.updateRoute({
          reportType: this.reportType,
          reportSubType: this.reportSubType,
          ...this.reportOptions
        });
      }

      if (typeof _get(this.reportOptions, 'groupBy') === 'string') {
        this.reportOptions.groupBy = this.reportOptions.groupBy.split(',');
      }

      if (this.$route.params.id) this.reportOptions.filter = { campaign_id: this.$route.params.id };

      this.fetchReport();
    },
    fetchReport() {
      this.$store.dispatch('fetchReportSummary', {
        orgId: this.orgId,
        reportType: this.reportType,
        reportSubType: this.reportSubType,
        options: this.getActiveRouteOptions()
      })
        .then(response => {
          this.pagination.page = _get(response, '_meta.page', 1);
          this.pagination.total = _get(response, '_meta.result_count', 1);
          this.totals = _get(response, '_meta.summary', []);
        })
        .catch(() => {
          this.$message({
            showClose: true,
            message: 'Data failed to load, please try again'
          });
        });
    },
    downloadReport() {
      this.loadingCsvFile = true;
      this.$store
        .dispatch('downloadReportingCsv', {
          orgId: this.orgId,
          reportType: this.reportType,
          reportSubType: this.reportSubType,
          options: {
            ...this.getActiveRouteOptions(),
            page: 1,
            perPage: MAX_DOWNLOAD_SIZE
          },
          filename: this.reportSubType ? `${this.reportType}-${this.reportSubType}.csv` : `${this.reportType}.csv`
        })
        .then((response) => {
          this.loadingCsvFile = false;
        })
        .catch(() => {
          this.loadingCsvFile = false;
          this.$store.dispatch('showErrorMessage', 'Failed to Download CSV');
        });

      if (this.pagination.total >= MAX_DOWNLOAD_SIZE) {
        this.$message(`CSV Export is limited to ${MAX_DOWNLOAD_SIZE} rows`);
      }
    },
    handleSort(data) {
      this.updateRoute({
        ...this.getActiveRouteOptions(),
        sortBy: data.order ? data.prop : undefined,
        order: ORDER_MAP[data.order],
        page: 1
      });
    },
    handleActionBack(item) {
      this.$store.dispatch('popReportNavigationStack')
        .then(response => {
          if (response) {
            this.$router.push(response);
          }
        });
    },
    handlePageChange(currentPage) {
      this.updateRoute({
        ...this.getActiveRouteOptions(),
        page: currentPage
      });
    },
    updateRoute(options = {}, onAbortHandler) {
      const reportUrl = this.getReportUrl({
        reportType: this.reportType,
        reportSubType: this.reportSubType || undefined,
        ...options
      });

      this.$router.push(reportUrl, null, onAbortHandler);
    },
    getActiveRouteOptions() {
      let options = _pick(this.reportOptions, ['dateRange', 'startDate', 'endDate']);

      if (_get(this.reportOptions, 'groupBy', []).length) {
        options.groupBy = this.reportOptions.groupBy.join(',');
      }

      if (Object.keys(_get(this.reportOptions, 'filter', {})).length) {
        options.filter = this.reportOptions.filter;
      }

      if (Object.keys(this.sort).length) {
        options = {
          ...options,
          ...this.sort
        };
      }

      options = {
        ...options,
        reportType: this.reportType,
        reportSubType: this.reportSubType,
        ..._pick(this.pagination, ['page', 'perPage'])
      };

      return options;
    },
    getReportUrl(options = {}) {
      options = Object.assign({
        reportType: 'mtd'
      }, options);
      let reportUrl = this.$route.params.id
        ? `/campaign/${this.$route.params.id}/reports/${options.reportType}`
        : `/reporting/${options.reportType}`;
      let report = options;
      let subReport;

      if (options.reportSubType) {
        reportUrl += `/${options.reportSubType}`;
        report = this.$store.getters.affiliateReportOptions(this.reportType).report;
        subReport = options;
      }

      report = _omit(report, ['reportType', 'reportSubType']);
      subReport = _omit(subReport, ['reportType', 'reportSubType']);
      return reportUrl + '?' + queryStringOptions.stringify({ report, subReport });
    },
    handleApplyReportOptions() {
      this.$store.dispatch('setMetricDateRange', this.dateOptions);

      this.updateRoute({
        ...this.getActiveRouteOptions(),
        ...this.dateOptions,
        page: 1
      }, () => {
        this.fetchReport();
      });
    },
    handleClearReportOptions() {
      this.dateOptions = { dateRange: 'CW', startDate: undefined, endDate: undefined };
      this.reportOptions = this.getDefaultReportOptions(this.reportType, this.reportSubType);
      this.handleApplyReportOptions();
    },
    reportTableColumnClass(column) {
      return {
        'affiliate-reporting-table__item--colored': column === 'money',
        'affiliate-reporting-table__item--action': ['clicks', 'sales'].includes(column) && this.hasGroupBy
      };
    },
    getFormattedColumn(row, column, placeholder = '-') {
      const formats = ['money', 'percent', 'number'];

      if (row[column] === null || row[column] === '') {
        return placeholder;
      } else if (!COLUMN_MAP[column] || !formats.includes(COLUMN_MAP[column].format)) {
        return row[column];
      } else if (COLUMN_MAP[column].format === 'money') {
        return this.$options.filters.money(row[column], true, '$');
      } else if (COLUMN_MAP[column].format === 'percent') {
        return this.$options.filters.money(row[column], true, '', '%');
      } else if (COLUMN_MAP[column].format === 'number') {
        return this.$options.filters.money(row[column], true, '', '');
      }
      return row[column];
    },
    handleDrilldownClick(row, column, cell, event) {
      const isSpecialCell = ['clicks', 'sales'].includes(column.property);
      const routeOptions = this.getActiveRouteOptions();
      if (!this.hasGroupBy) return;
      const filter = _omit(
        _get(routeOptions, 'groupBy', '')
          .split(',')
          .reduce((o, key) => {
            o[key] = row[key];
            return o;
          }, {}),
        'date'
      );

      const reportSubType = isSpecialCell
        ? column.property
        : routeOptions.reportSubType;

      this.updateRoute({
        ...routeOptions,
        filter: {
          ...routeOptions.filter,
          ...filter
        },
        reportSubType: reportSubType,
        ...(row.date ? { dateRange: 'CUSTOM', startDate: row.date, endDate: row.date } : {}),
        ...(isSpecialCell ? { groupBy: undefined } : {}),
        page: 1
      });
    }
  }
};
