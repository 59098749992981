import money from '@/services/money';
// This determines the order in which columns are rendered
export const COLUMN_MAP = {
  date: { label: 'Date', minWidth: 140, align: 'left' },
  campaign_id: { label: 'ID', minWidth: 80, align: 'left' },
  campaign_name: { label: 'Name', minWidth: 200, align: 'left' },
  funnel_id: { label: 'Funnel ID', minWidth: 100, align: 'left' },
  funnel_name: { label: 'Funnel', minWidth: 200, align: 'left' },
  ip: { label: 'IP', minWidth: 100, align: 'left' },
  c1: { label: 'C1', minWidth: 120, align: 'left' },
  c2: { label: 'C2', minWidth: 120, align: 'left' },
  c3: { label: 'C3', minWidth: 120, align: 'left' },
  transaction_date: { label: 'By Date', minWidth: 100, align: 'left' },
  conversion_rate: { label: 'Conv. %', minWidth: 70, align: 'right', format: 'percent' },
  epc: { label: 'EPC', minWidth: 70, align: 'right', format: 'money' },
  clicks: { label: 'Clicks', minWidth: 70, align: 'right', format: 'number' },
  sales: { label: 'Sales', minWidth: 70, align: 'right', format: 'number' },
  money: { label: 'Earnings', minWidth: 80, align: 'right', format: 'money' }
};

// Some default columns to render if no data is loaded yet
export const COLUMN_DEFAULTS = ['campaign_id', 'campaign_name', 'conversion_rate', 'epc', 'clicks', 'sales', 'money'];
